import Dashboard from "./components/Dashboard";
import AdminDashboard from "./components/Admin/Dashboard";
import Brands from "./components/Admin/Brands";
import Inventory from "./components/Inventory/Inventory";
import AddInventory from "./components/Inventory/AddInventory";
import ReturnInventory from "./components/Inventory/ReturnInventory";
import ViewInventory from "./components/Inventory/ViewInventory";
import EditInventory from "./components/Inventory/EditInventory";
import OnlineInventory from "./components/OnlineInventory/OnlineInventory";
import AddOnlineInventory from "./components/OnlineInventory/AddOnlineInventory";
import ViewOnlineInventory from "./components/OnlineInventory/ViewOnlineInventory";
import EditOnlineInventory from "./components/OnlineInventory/EditOnlineInventory";
import ViewSelectedInventoryOnline from "./components/OnlineInventory/ViewSelectedInventoryOnline";
import ViewSelectedInventory from "./components/Inventory/ViewSelectedInventory";
import Orders from "./components/Orders/Orders";
import AddOrder from "./components/Orders/AddOrder";
import EditOrder from "./components/Orders/EditOrder";
import ViewOrder from "./components/Orders/ViewOrder";
import ViewTransferOrder from "./components/Admin/ViewTransferOrder";
import OnlineOrders from "./components/Orders/OnlineOrders";
import TransferOrders from "./components/Orders/TransferOrders";
import Accounts from "./components/Accounts";
import Expense from "./components/Expense/Expense";
import BankTransactions from "./components/Bank/BankTransactions";
import AddBank from "./components/Bank/AddBank";
import Partners from "./components/Partners/Partners";
import AddPartner from "./components/Partners/AddPartner";
import PartnerKhata from "./components/Partners/PartnerKhata";
import ChatAdmin from "./components/Whatsapp/Chat";
import WhatsappAdmin from "./components/Whatsapp/Whatsapp";
import TransferOrdersForAdmin from "./components/Admin/TransferOrders";
import MobileRepairing from "./components/Services/MobileRepairing";
import CreateRepairingService from "./components/Services/CreateRepairingService";
import Staff from "./components/Staff/Staff";
import AddStaff from "./components/Staff/AddStaff";
import Vendors from "./components/Vendors/Vendors";
import AddVendor from "./components/Vendors/AddVendor";
import VendorsKhata from "./components/Vendors/VendorsKhata";
import UserRoles from "./components/Users/UserRoles";
import MemberKhata from "./components/Staff/MemberKhata";
import Notes from "./components/Notes/Notes";
import Invoices from "./components/Invoices/Invoices";
import AddInvoice from "./components/Invoices/AddInvoice";
import EditInvoice from "./components/Invoices/EditInvoice";
import ViewInvoice from "./components/Invoices/ViewInvoice";
import Services from "./components/Services/Services";
import Users from "./components/Users/Users";
import Branches from "./components/Auth/Branches";
/////////////
import { ReactComponent as AccountsDashboardIconSmall } from "./assets/SVG/AccountsDashboardIconSmall.svg";
import { ReactComponent as ExpenseIconForSideMenu } from "./assets/SVG/ExpenseIconForSideMenu.svg";
import { ReactComponent as BankManagementActive } from "./assets/SVG/BankManagementActive.svg";
import { ReactComponent as WhatsappIconActive } from "./assets/SVG/whatsapp-icon-active.svg";
import { ReactComponent as NotesIconActive } from "./assets/SVG/NotesIconActive.svg";
import { ReactComponent as BankManagement } from "./assets/SVG/BankManagement.svg";
import { ReactComponent as NotesComponent } from "./assets/SVG/NotesComponent.svg";
import { ReactComponent as DashboardSmall } from "./assets/SVG/DashboardSmall.svg";
import { ReactComponent as WhatsappIcon } from "./assets/SVG/whatsapp-icon.svg";
import { UserSVG, PartnerSVG } from "./assets/SVG/RoutesSVG";
import { IoLogoWhatsapp } from "react-icons/io5";
import { GiReturnArrow } from "react-icons/gi";
import { BsBroadcastPin } from "react-icons/bs";
import { TbBrandHipchat } from "react-icons/tb";

// Side Menu Icons
import { ReactComponent as ReturnInventoryIconActive } from "./assets/SVG/side_menu_icons/inventory/return_inventory_icon_active.svg";
import { ReactComponent as TransferInventoryIcon } from "./assets/SVG/side_menu_icons/inventory/transfer_inventory_icon.svg";
import { ReactComponent as InventoryTabIconActive } from "./assets/SVG/side_menu_icons/inventory/inventory_tab_icon_active.svg";
import { ReactComponent as ReturnInventoryIcon } from "./assets/SVG/side_menu_icons/inventory/return_inventory_icon.svg";
import { ReactComponent as TransferInventoryIconActive } from "./assets/SVG/side_menu_icons/inventory/transfer_inventory_icon_active.svg";
import { ReactComponent as InventoryTabIcon } from "./assets/SVG/side_menu_icons/inventory/inventory_tab_icon.svg";
import { ReactComponent as ShopIconActive } from "./assets/SVG/side_menu_icons/common/shop_icon_active.svg";
import { ReactComponent as OnlineIconActive } from "./assets/SVG/side_menu_icons/common/online_icon_active.svg";
import { ReactComponent as DashboardIconActive } from "./assets/SVG/side_menu_icons/common/dashboard_icon_active.svg";
import { ReactComponent as OnlineIcon } from "./assets/SVG/side_menu_icons/common/online_icon.svg";
import { ReactComponent as DashboardIcon } from "./assets/SVG/side_menu_icons/common/dashboard_icon.svg";
import { ReactComponent as ShopIcon } from "./assets/SVG/side_menu_icons/common/shop_icon.svg";
import { ReactComponent as InvoicesTabIcon } from "./assets/SVG/side_menu_icons/invoices/invoices_tab_icon.svg";
import { ReactComponent as InvoicesTabIconActive } from "./assets/SVG/side_menu_icons/invoices/invoices_tab_icon_active.svg";
import { ReactComponent as BankAccountsIcon } from "./assets/SVG/side_menu_icons/accounts/bank_accounts_icon.svg";
import { ReactComponent as AccountsTabIcon } from "./assets/SVG/side_menu_icons/accounts/accounts_tab_icon.svg";
import { ReactComponent as AccountsTabIconActive } from "./assets/SVG/side_menu_icons/accounts/accounts_tab_icon_active.svg";
import { ReactComponent as ExpenseAccountsIcon } from "./assets/SVG/side_menu_icons/accounts/expense_accounts_icon.svg";
import { ReactComponent as ExpenseAccountsIconActive } from "./assets/SVG/side_menu_icons/accounts/expense_accounts_icon_active.svg";
import { ReactComponent as BankAccountsIconActive } from "./assets/SVG/side_menu_icons/accounts/bank_accounts_icon_active.svg";
import { ReactComponent as UsersTabIcon } from "./assets/SVG/side_menu_icons/users/users_tab_icon.svg";
import { ReactComponent as UsersTabIconActive } from "./assets/SVG/side_menu_icons/users/users_tab_icon_active.svg";
import { ReactComponent as StaffUsersIcon } from "./assets/SVG/side_menu_icons/users/staff_users_icon.svg";
import { ReactComponent as PartnersUsersIcon } from "./assets/SVG/side_menu_icons/users/partners_users_icon.svg";
import { ReactComponent as StaffUsersIconActive } from "./assets/SVG/side_menu_icons/users/staff_users_icon_active.svg";
import { ReactComponent as VendorsUsersIcon } from "./assets/SVG/side_menu_icons/users/vendors_users_icon.svg";
import { ReactComponent as PartnersUsersIconActive } from "./assets/SVG/side_menu_icons/users/partners_users_icon_active.svg";
import { ReactComponent as VendorsUsersIconActive } from "./assets/SVG/side_menu_icons/users/vendors_users_icon_active.svg";
import { ReactComponent as NotesTabIconActive } from "./assets/SVG/side_menu_icons/notes/notes_tab_icon_active.svg";
import { ReactComponent as NotesTabIcon } from "./assets/SVG/side_menu_icons/notes/notes_tab_icon.svg";
import { ReactComponent as OrdersTabIcon } from "./assets/SVG/side_menu_icons/orders/orders_tab_icon.svg";
import { ReactComponent as OrdersTabIconActive } from "./assets/SVG/side_menu_icons/orders/orders_tab_icon_active.svg";
import { ReactComponent as BroadcastChatIcon } from "./assets/SVG/side_menu_icons/chat/broadcast_chat_icon.svg";
import { ReactComponent as BroadcastChatIconActive } from "./assets/SVG/side_menu_icons/chat/broadcast_chat_icon_active.svg";
import { ReactComponent as ChatIconActive } from "./assets/SVG/side_menu_icons/chat/chat_icon_active.svg";
import { ReactComponent as ChatIcon } from "./assets/SVG/side_menu_icons/chat/chat_icon.svg";
import { ReactComponent as ChatTabIconActive } from "./assets/SVG/side_menu_icons/chat/chat_tab_icon_active.svg";
import { ReactComponent as ChatTabIcon } from "./assets/SVG/side_menu_icons/chat/chat_tab_icon.svg";
import { ReactComponent as BrandModuleIcon } from "./assets/SVG/BrandModule.svg";
import { ReactComponent as BrandModuleActiveIcon } from "./assets/SVG/BrandModuleActive.svg";
import { ReactComponent as ActivityLogIcon } from "./assets/SVG/side_menu_icons/activityLog/ActivityLogIcon.svg";
import { ReactComponent as ActivityLogIconActive } from "./assets/SVG/side_menu_icons/activityLog/ActivityLogIconActive.svg";

import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ActivityLog from "./components/Activity/ActivityLog";

const Routes = [
  {
    text: "Dashboard",
    path: "/",
    icon: <DashboardIcon />,
    active: <DashboardIconActive />,
    component: <Dashboard />,
    subMenuActive: false,
    subMenu: [],
  },
  {
    text: "Inventory",
    path: "/products",
    icon: <InventoryTabIcon />,
    active: <InventoryTabIconActive />,
    component: <Inventory />,
    subMenuActive: false,
    subMenu: [
      {
        text: "Shop Inventory",
        path: "/products",
        icon: <ShopIcon />,
      },
      // {
      //   text: "Online Inventory",
      //   path: "/products/online-product",
      //   icon: <OnlineIcon />,
      // },
      {
        text: "Return Inventory",
        path: "/products/return",
        icon: <ReturnInventoryIcon />,
      },
      // {
      //   text: "Transfer Inventory",
      //   path: "/products/transfer",
      //   icon: <TransferInventoryIcon />,
      // },
    ],
  },
  {
    text: "",
    path: "/products/return",
    icon: "",
    active: "",
    component: <ReturnInventory />,
    subMenuActive: false,
    subMenu: [],
  },

  {
    text: "",
    path: "/products/online-product",
    icon: "",
    active: "",
    component: <OnlineInventory />,
    subMenuActive: false,
    subMenu: [],
  },
  {
    text: "",
    path: "/products/add-new-online-product",
    icon: "",
    active: "",
    component: <AddOnlineInventory />,
    subMenuActive: false,
    subMenu: [],
  },

  {
    text: "",
    path: "/products/online-product-detail",
    icon: "",
    active: "",
    component: <ViewOnlineInventory />,
    subMenuActive: false,
    subMenu: [],
  },

  {
    text: "",
    path: "/products/online-edit-product",
    icon: "",
    active: "",
    component: <EditOnlineInventory />,
    subMenuActive: false,
    subMenu: [],
  },

  {
    text: "",
    path: "/products/online-selected-products-detail",
    icon: "",
    active: "",
    component: <ViewSelectedInventoryOnline />,
    subMenuActive: false,
    subMenu: [],
  },

  {
    text: "",
    path: "/products/add-new-product",
    icon: "",
    active: "",
    component: <AddInventory />,
    subMenuActive: false,
    subMenu: [],
  },

  {
    text: "",
    path: "/products/edit-product",
    icon: "",
    active: "",
    component: <EditInventory />,
    subMenuActive: false,
    subMenu: [],
  },

  {
    text: "",
    path: "/products/product-detail",
    icon: "",
    active: "",
    component: <ViewInventory />,
    subMenuActive: false,
    subMenu: [],
  },
  {
    text: "",
    path: "/products/selected-products-detail",
    icon: "",
    active: "",
    component: <ViewSelectedInventory />,
    subMenuActive: false,
    subMenu: [],
  },

  {
    text: "Orders",
    path: "/orders",
    icon: <OrdersTabIcon />,
    active: <OrdersTabIconActive />,
    component: <Orders />,
    subMenuActive: false,
    subMenu: [
      {
        text: "Shop Orders",
        path: "/orders",
        icon: <ShopIcon />,
      },
      // {
      //   text: "Online Orders",
      //   path: "/orders/online-orders",
      //   icon: <OnlineIcon />,
      // },

      // {
      //   text: "Transfer Orders",
      //   path: "/transfer/orders",
      //   icon: <TransferInventoryIcon />,
      // },
    ],
  },
  {
    text: "",
    path: "/orders/add-new-order",
    icon: "",
    active: "",
    component: <AddOrder />,
    subMenuActive: false,
    subMenu: [],
  },
  {
    text: "",
    path: "/orders/online-orders",
    icon: "",
    active: "",
    component: <OnlineOrders />,
    subMenuActive: false,
    subMenu: [],
  },
  {
    text: "",
    path: "/orders/update-order",
    icon: "",
    active: "",
    component: <EditOrder />,
    subMenuActive: false,
    subMenu: [],
  },
  {
    text: "",
    path: "/orders/order-detail",
    icon: "",
    active: "",
    component: <ViewOrder />,
    subMenuActive: false,
    subMenu: [],
  },
  {
    text: "Accounts",
    path: "/accounts",
    icon: <AccountsTabIcon />,
    active: <AccountsTabIconActive />,
    component: <Accounts />,
    subMenuActive: false,
    subMenu: [
      {
        text: "Dashboard",
        path: "/accounts",
        icon: <DashboardIcon />,
      },
      {
        text: "Expense",
        path: "/expense/account-cash-khata",
        icon: <ExpenseAccountsIcon />,
      },
      {
        text: "Bank",
        path: "/bank",
        icon: <BankAccountsIcon />,
      },
    ],
  },
  {
    text: "",
    path: "/accounts",
    icon: "",
    active: "",
    component: <Accounts />,
    subMenuActive: false,
    subMenu: [],
  },
  {
    text: "",
    path: "/bank",
    icon: "",
    active: "",
    component: <BankTransactions />,
    subMenuActive: false,
    subMenu: [],
  },
  {
    text: "",
    path: "/expense/account-cash-khata",
    icon: "",
    active: "",
    component: <Expense />,
    subMenuActive: false,
    subMenu: [],
  },
  {
    text: "",
    path: "/accounts/account-cash-khata",
    icon: "",
    active: "",
    component: <Expense />,
    subMenuActive: false,
    subMenu: [],
  },
  {
    text: "",
    path: "/staff/add-new-staff-member",
    icon: "",
    active: "",
    component: <AddStaff />,
    subMenuActive: false,
    subMenu: [],
  },
  {
    text: "",
    path: "/staff/staff-member-khata",
    icon: "",
    active: "",
    component: <MemberKhata />,
    subMenuActive: false,
    subMenu: [],
  },
  {
    text: "",
    path: "/staff/orders",
    icon: "",
    active: "",
    component: <Orders />,
    subMenuActive: false,
    subMenu: [],
  },
  {
    text: "",
    path: "/transfer/orders",
    icon: "",
    active: "",
    component: <TransferOrders />,
    subMenuActive: false,
    subMenu: [],
  },
  {
    text: "",
    path: "/transfer-orders/order-detail",
    icon: "",
    active: "",
    component: <ViewTransferOrder />,
    subMenuActive: false,
    subMenu: [],
  },
  {
    text: "",
    path: "/orders/transfer-order-detail",
    icon: "",
    active: "",
    component: <ViewTransferOrder />,
    subMenuActive: false,
    subMenu: [],
  },
  {
    text: "",
    path: "/staff",
    icon: "",
    active: "",
    component: <Staff />,
    subMenuActive: false,
    subMenu: [],
  },
  {
    text: "Users",
    path: "/users",
    icon: <UsersTabIcon />,
    active: <UsersTabIconActive />,
    component: <Partners />,
    subMenuActive: false,
    subMenu: [
      {
        text: "Partners",
        path: "/partners",
        icon: <PartnersUsersIcon />,
      },
      {
        text: "Staff",
        path: "/staff",
        icon: <StaffUsersIcon />,
      },
      {
        text: "Vendors",
        path: "/vendors",
        icon: <VendorsUsersIcon />,
      },
    ],
  },
  {
    text: "Chat",
    path: "/chat",
    icon: <ChatTabIcon />,
    active: <ChatTabIconActive />,
    component: <ChatAdmin />,
    subMenuActive: false,
    subMenu: [
      {
        text: "Chat",
        path: "/chat",
        icon: <ChatIcon />,
      },
      {
        text: "Message Broadcasting",
        path: "/whatsapp",
        icon: <BroadcastChatIcon />,
      },
    ],
  },
  {
    text: "Invoices and Services",
    path: "/invoices",
    icon: <InvoicesTabIcon />,
    active: <InvoicesTabIconActive />,
    component: <Invoices />,
    subMenuActive: false,
    subMenu: [],
  },

  {
    text: "",
    path: "/vendors",
    icon: "",
    active: "",
    component: <Vendors />,
    subMenuActive: false,
    subMenu: [],
  },

  {
    text: "",
    path: "/partners",
    icon: "",
    active: "",
    component: <Partners />,
    subMenuActive: false,
    subMenu: [],
  },

  {
    text: "",
    path: "/partners/add-new-partner",
    icon: "",
    active: "",
    component: <AddPartner />,
    subMenuActive: false,
    subMenu: [],
  },
  {
    text: "",
    path: "/partners/view-partner-khata",
    icon: "",
    active: "",
    component: <PartnerKhata />,
    subMenuActive: false,
    subMenu: [],
  },
  {
    text: "",
    path: "/vendors/add-new-vendor",
    icon: "",
    active: "",
    component: <AddVendor />,
    subMenuActive: false,
    subMenu: [],
  },
  {
    text: "",
    path: "/vendors/view-khata",
    icon: "",
    active: "",
    component: <VendorsKhata />,
    subMenuActive: false,
    subMenu: [],
  },
  {
    text: "",
    path: "/staff/staff-roles",
    icon: "",
    active: "",
    component: <UserRoles />,
    subMenuActive: false,
    subMenu: [],
  },
  {
    text: "",
    path: "/bank/add-new-bank",
    icon: "",
    active: "",
    component: <AddBank />,
    subMenuActive: false,
    subMenu: [],
  },
  {
    text: "Notes",
    path: "/notes",
    icon: <NotesTabIcon />,
    active: <NotesTabIconActive />,
    component: <Notes />,
    subMenuActive: false,
    subMenu: [],
  },

  {
    text: "",
    path: "/whatsapp",
    icon: "",
    active: "",
    component: <WhatsappAdmin />,
    subMenuActive: false,
    subMenu: [],
  },
  {
    text: "",
    path: "/chat",
    icon: "",
    active: "",
    component: <ChatAdmin />,
    subMenuActive: false,
    subMenu: [],
  },

  {
    text: "",
    path: "/invoices/add-new-invoice",
    icon: "",
    active: "",
    component: <AddInvoice />,
    subMenuActive: false,
    subMenu: [],
  },
  {
    text: "",
    path: "/invoices/update-invoice",
    icon: "",
    active: "",
    component: <EditInvoice />,
    subMenuActive: false,
    subMenu: [],
  },
  {
    text: "",
    path: "/invoices/invoice-detail",
    icon: "",
    active: "",
    component: <ViewInvoice />,
    subMenuActive: false,
    subMenu: [],
  },
  {
    text: "Admin Dashboard",
    path: "/admin-dashboard",
    icon: <DashboardIcon />,
    active: <DashboardIconActive />,
    component: <AdminDashboard />,
    subMenuActive: false,
    subMenu: [],
  },
  {
    text: "Brands",
    path: "/brands",
    icon: <BrandModuleIcon />,
    active: <BrandModuleActiveIcon />,
    component: <Brands />,
    subMenuActive: false,
    subMenu: [],
  },
  {
    text: "Transfer Orders",
    path: "/transfer-orders",
    icon: <TransferInventoryIcon />,
    active: <TransferInventoryIconActive />,
    component: <TransferOrdersForAdmin />,
    subMenuActive: false,
    subMenu: [],
  },
  {
    text: "Whatsapp Broadcast",
    path: "/whatsapp-broadcast",
    icon: (
      <div style={{ marginTop: "-3px" }}>
        <WhatsAppIcon />
      </div>
    ),
    active: (
      <div style={{ marginTop: "-3px" }}>
        <WhatsAppIcon />
      </div>
    ),
    component: <WhatsappAdmin />,
    subMenuActive: false,
    subMenu: [],
  },
  // {
  //   text: "Services",
  //   path: "/services",
  //   icon: <NotesComponent />,
  //   active: <NotesIconActive />,
  //   component: <Services />,
  //   subMenuActive: false,
  //   subMenu: [],
  // },
  {
    text: "",
    path: "/services/mobile-repairing-services",
    icon: "",
    active: "",
    component: <MobileRepairing />,
    subMenuActive: false,
    subMenu: [],
  },
  {
    text: "",
    path: "/services/mobile-repairing-services/create",
    icon: "",
    active: "",
    component: <CreateRepairingService />,
    subMenuActive: false,
    subMenu: [],
  },
  {
    text: "Activity Logs",
    path: "/activity",
    icon: <ActivityLogIcon />,
    active: <ActivityLogIconActive />,
    component: <ActivityLog />,
    subMenuActive: false,
    subMenu: [],
  },
];

export default Routes;
